import { create } from "zustand"
import { BonusCartItem, GiftCartState } from "./types"
import { Payload } from "@local/payload-client/src/types"
import { createJSONStorage, persist } from "zustand/middleware"
import { CartItem } from "@local/product-option-manager/types"

export const useGiftCart = create<GiftCartState>()(
  persist(
    (setState, getState) => ({
      bonusItems: [],
      bonusLevel: 0,
      setBonusLevel: (bonusLevel: number) => {
        setState({
          bonusLevel,
        })
      },
      addBonusItems: ({
        items,
        level,
      }: {
        items: Array<Payload.Product>
        level: number
      }) => {
        const cart = [...getState().bonusItems]

        items.map((item, i) => {
          const existingItem = cart.find(
            (i) => i.id === item.id && i.id === item.id
          )

          if (!existingItem) {
            cart.push({
              ...item,
              giftAccepted: false,
              // if there is more than 1 bonus per add, we need to shift the levelKey accordingly
              levelKey:
                items.length > 1 ? level - (items.length - 1 - i) : level,
            })
          }
        })
        setState({
          bonusItems: cart,
        })
      },
      removeBonusItems: (bonusLevel: number) => {
        setState({
          bonusItems: getState().bonusItems.filter(
            (item: BonusCartItem) => item.levelKey <= bonusLevel
          ),
        })
      },
      acceptGift: (levelKey: number, cartItem, image) => {
        const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
          if (item.levelKey === levelKey) {
            return {
              ...item,
              giftAccepted: true,
              cartItem: { ...cartItem, preOrder: null, price: 0 },
              image,
            }
          }
          return item
        })
        setState({
          bonusItems,
        })
      },
      clearGiftCart: () => {
        setState({
          bonusItems: [],
          bonusLevel: 0,
        })
      },
      // basically does kinda the same as above, exists only for the sake of consistency
      setGiftVariant: (levelKey: number, cartItem: CartItem, image) => {
        const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
          if (item.levelKey === levelKey) {
            return {
              ...item,
              cartItem,
              preOrder: null,
              image,
            }
          }
          return item
        })
        setState({
          bonusItems,
        })
      },
      setGiftImage: (levelKey: number, image) => {
        const bonusItems = getState().bonusItems.map((item: BonusCartItem) => {
          if (item.levelKey === levelKey) {
            return {
              ...item,
              image,
            }
          }
          return item
        })
        setState({
          bonusItems,
        })
      },
    }),
    {
      name: "giftCart",
      storage: createJSONStorage(() => localStorage),
      version: 2,
    }
  )
)

export default useGiftCart
